import { useEffect, useState } from "react";
import * as React from "react";
import { Parallax } from "@react-spring/parallax";
import About from "../components/about";
import Contact from "../components/contact";
import Hero from "../components/hero";
import Jobs from "../components/jobs";
import Layout from "../components/layout";
import Navbar from "../components/navbar";
import Projects from "../components/projects";
import { useRef } from "react";
import { isMobile } from 'react-device-detect';

// markup
const IndexPage = () => {
  return (
      <div>
          <div>
              <Navbar mobile={isMobile} />
              <Layout>
                <Parallax id="parallax" pages={isMobile ? 9 : 5}>
                    <Hero offset={0} factor={1} />
                    <About offset={isMobile ? 2 : 1} factor={1} />
                    <Jobs offset={isMobile ? 4 : 2} factor={1} />
                    <Projects offset={isMobile ? 6 : 3} factor={1} />
                    <Contact offset={isMobile ? 8 : 4} factor={1} />
                </Parallax>
              </Layout>
          </div>
      </div>
  )
}

export default IndexPage
