import { createGlobalStyle } from 'styled-components';
import fonts from './fonts';
import variables from './variables';
import TransitionStyles from './TransitionStyles';
import PrismStyles from './PrismStyles';

const GlobalStyle = createGlobalStyle`
  ${variables};

  ::selection {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 10px 0;
    font-weight: 600;
    color: var(--theme-ui-colors-heading);
    line-height: 1.1;
  }

  .medium-heading {
    margin: 0;
    font-size: clamp(40px, 8vw, 60px);
  }

  button {
    cursor: pointer;
    border: 0;
    border-radius: 0;
  }

  body {
    overflow: hidden;
  }

`;

export default GlobalStyle;
