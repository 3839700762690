import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`How did I end up here...?`}</p>
    </blockquote>
    <p>{`I am originally from Tehran, Iran. I grew up in Calgary, Alberta, Canada and attended McGill University to study Electrical Engineering. During my time at McGill I had the opportunity to work with a lot of great people and was involved in several organizations. My proudest achievements are leading `}<a parentName="p" {...{
        "href": "https://www.facebook.com/mcgillcodejam/"
      }}>{`McGill CodeJam`}</a>{`, co-founding `}<a parentName="p" {...{
        "href": "https://hack4impact-mcgill.vercel.app/"
      }}>{`Hack4Impact McGill`}</a>{` and teaching as well as organizing (with a lot of help) a peer taught machine learning course for 60 undergraduate students as part of the `}<a parentName="p" {...{
        "href": "https://www.mcgillai.com/mais202"
      }}>{`McGill AI society`}</a>{`.`}</p>
    <p>{`During my time at school, I also had great opportunities to travel, attending conferences, case competitions and hackathons. One of the most memorable trips for me is having a chance to compete and `}<a parentName="p" {...{
        "href": "https://www.mcgill.ca/desautels/channels/news/mcgill-team-scores-second-place-international-youth-leadership-finance-summit-305504"
      }}>{`place second`}</a>{` in a global business competition in Shanghai right before COVID-19 pandemic took off.`}</p>
    <p>{`I am really interested in building cool technical projects and love to read books. If you have any book recommendations, or I can help with anything please don't hesitate to reach out. :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      