import React, { useRef, useState } from "react";
import "./../styles/navbar.css";
import { Link } from "react-scroll";
import { useEffect } from "react";

const scrollTo = (location: number) => {
  document.getElementById('parallax').scrollTo({ top: location, behavior: 'smooth' });
}


const Navbar = ({mobile = false}: {mobile: boolean}) => {
  const [screenHeight, setScreenHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [visible, setVisibility] = useState(true);
  const navBarRef = useRef();

  // Hide or show the menu.
  const handleScroll = () => {
    const parallaxElement = document.getElementById('parallax')
    const prevScrollpos = scrollPosition;

    const currentScrollPos = parallaxElement.scrollTop;
    const visible = prevScrollpos > currentScrollPos;

    setScrollPosition(currentScrollPos);
    setVisibility(visible);
  };

  useEffect(() => {
    const parallaxElement = document.getElementById('parallax');
    setScreenHeight(parallaxElement.offsetHeight);

    parallaxElement.addEventListener('scroll', handleScroll);

    return () => {
      parallaxElement.removeEventListener('scroll', handleScroll);
    }
  })

  return (
    <nav className={`navbar${visible ? "" : " navbar--hidden" }`} ref={navBarRef}>
      <ul className="navbar--link">
        <li className="navbar--link-item">
          <a onClick={() => {
            scrollTo(screenHeight * 0)
          }}>
            Home
          </a>
        </li>
        <li className="navbar--link-item">
          <a onClick={() => {
              scrollTo(mobile ? screenHeight * 1.4 : screenHeight * 1)
            }}>
            About
          </a>
        </li>
        <li className="navbar--link-item">
          <a onClick={() => {
            scrollTo(mobile ? screenHeight * 4 : screenHeight * 2)
          }}>
            Resume
          </a>
        </li>
        <li className="navbar--link-item">
          <a onClick={() => {
            scrollTo(mobile ? screenHeight * 5.8 : screenHeight * 3)
          }}>
            Projects
          </a>
        </li>
        <li className="navbar--link-item">
            <a onClick={() => {
                scrollTo(mobile ? screenHeight * 8 : screenHeight * 4)
              }}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  )
}
export default Navbar