import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="YouTube Party" link="https://chrome.google.com/webstore/detail/youtube-party/ngmncgebkdchkdokiecifpmodajbaopj?hl=en" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" image="../../images/youtube-party-icon.png" mdxType="ProjectCard">
  YouTube party is a Chrome extension developed alongside my friend Sami that syncs YouTube video playback for Chrome users in the same watch party. This was launched in April 2020 and since then has grown to have over 250,000+ weekly users.
    </ProjectCard>
    <ProjectCard title="Reinforcement Learning FIFA AI" link="https://github.com/alishobeiri/Google-Research-Football-Submission" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" image="../../images/soccer-ball-icon.png" mdxType="ProjectCard">
  This project was developed along with my friend Paul and was our submission to the Google Research Football Kaggle competition in which we placed in the top 30%. Our best performing agent used a hybrid approach of rule based agent for defense along with a reinforcement learning agent when attacking.
    </ProjectCard>
    <ProjectCard title="Monocular Video Odometry" link="https://www.youtube.com/watch?v=xe_k6zRe65Y" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" image="../../images/monocular-video-odometry-icon.png" mdxType="ProjectCard">
  This project came about after I took a computer vision course and got interested in simultaneous localization and mapping (SLAM). In this project, I demonstrate the ability of to programmatically find out the trajectory of a vehicle using a video sequence of the path they took. This is almost like finding the path of a car by looking only at it's the dash cam footage.
    </ProjectCard>
    <ProjectCard title="Halo Roblox Evolved" link="https://www.roblox.com/games/4158384/Halo-ROBLOX-Evolved-The-Original?ID=4158384" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" image="../../images/roblox-icon.png" mdxType="ProjectCard">
  This was one of the first programming projects I built when I was in junior high. The reason, I put it here is I don't put this on my resume at all, but I am quite proud as I was able to get over 300,000 users to play it (which was a lot on Roblox for the time).
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      